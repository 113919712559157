<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="110px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="稽查单号" :class="$i18n.locale">
              <el-input v-model="form.auditCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="到货计划编号" :class="$i18n.locale">
              <el-input v-model="form.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="收货单号" :class="$i18n.locale">
              <el-input v-model="form.collectCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="稽查地点" :class="$i18n.locale">
              <el-select
                v-model="form.auditPlace"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option label="仓库" value="仓库" />
                <el-option label="工厂" value="工厂" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态" prop="status" :class="$i18n.locale">
              <el-select
                v-model="form.status"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >

                <el-option v-for="item in _getAllCommodityDict('AUDIT_STATUS')" :key="item.val" :label="item.label" :value="item.val" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="稽查工程师" prop="auditUser" :class="$i18n.locale">
              <el-select v-model="form.auditUser" filterable clearable multiple collapse-tags>
                <el-option
                  v-for="item in auditUserList"
                  :key="item.id"
                  :label="item.nickName"
                  :value="item.userName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="稽查日期">
              <el-date-picker
                v-model="form.value1"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width: 15%">
        <el-col class="row-center">
          <el-button type="primary" class="ml-4" :loading="loading" @click="queryClick(1)">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRow=!showRow">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      ref="singleTable"
      class="mt-3 mb-3"
      :loading="loading"
      :data="tableDatas"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column
        type="index"
        width="50"
        label="序号"
      />
      <el-table-column
        property="auditCode"
        label="稽查单号"
        min-width="180"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="handleClick(scope.row)">
            {{ scope.row.auditCode }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        property="collectCode"
        label="收货单号"
        min-width="110"
      />
      <el-table-column
        property="auditDate"
        label="稽查日期"
        min-width="110"
      />
      <el-table-column
        property="auditPlace"
        label="稽查地点"
        min-width="90"
      />
      <el-table-column
        property="inspectTotalQuantity"
        label="报检总箱数"
        min-width="100"
      />
      <el-table-column
        property="inspectTotalPairs"
        label="报检总双数"
        min-width="100"
      />
      <el-table-column
        property="spotCheckPairs"
        label="抽检标准双数"
        min-width="110"
      />
      <el-table-column
        property="auditTotalQuantity"
        label="稽查总箱数"
        min-width="110"
      />
      <el-table-column
        property="auditTotalPairs"
        label="稽查总双数"
        min-width="110"
      />
      <el-table-column
        property="expectComplateTime"
        label="预计完成时间"
        min-width="105"
      />
      <el-table-column
        property="actualComplateTime"
        label="实际完成时间"
        min-width="110"
      />
      <el-table-column
        label="状态"
        min-width="90"
      >
        <template slot-scope="scope">
          {{ getAuditTypeLabel('AUDIT_STATUS',scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column
        property="createByName"
        label="创建人"
        min-width="110"
      />
      <el-table-column
        property="createTime"
        label="创建时间"
        min-width="110"
      />
      <el-table-column
        prop="purchasePersonnel"
        label="操作"
        fixed="right"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleClick(scope.row)">查看</el-button>

          <el-button v-if="scope.row.status==3" type="text" size="small" @click="handleExport(scope.row.id)">导出</el-button>

          <!-- 待稽查和待稽查备货 -->
          <el-button v-if="scope.row.status==1||scope.row.status==2" v-permission="'cancel'" type="text" size="small" @click="cancelClick(scope.row)">取消</el-button>
          <!-- 待稽查  -->
          <el-button v-if="scope.row.status==2" type="text" size="small" @click="addClick(scope.row)">生成报告</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>
    <el-dialog
      title="取消稽查单"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <el-form ref="formInline" label-position="left" label-width="80px" :model="formInline">
        <el-form-item label="取消原因" prop="reason" :rules="{required:true, message: this.$t('page.required'), trigger:[ 'change','blur']}">
          <el-select v-model="formInline.reason" placeholder="请选择">
            <el-option v-for="item in _getAllCommodityDict('AUDIT_CANCEL_REASON')" :key="item.val" :label="item.label" :value="item.val" />
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="formInline.cancelRemark" type="textarea" autosize />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="cancelAudit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Paging from '@/components/Pagination'
import { auditPage, cancelAudit, listUserByRoleCode, exportPdf } from '@/api/audit-api.js'
import { deepClone } from '@/utils'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
export default {
  components: { Paging },
  mixins: [commodityInfoDict],
  data() {
    return {
      auditUserList: [],
      row: {},
      form: {
        value1: [],
        status: [],
        auditUser: []
      },
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      showRow: false,
      tableDatas: [],
      loading: false,
      formInline: {
        reason: '1'
      },
      dialogVisible: false
    }
  },
  computed: {
    auditPageParams() {
      const obj = deepClone(this.form)
      const [startAuditDate, endAuditDate] = obj.value1 || []
      const { status, auditUser } = obj
      delete obj.value1
      delete obj.status
      return Object.assign({}, this.pager, obj, { startAuditDate, endAuditDate, status: String(status) || '', auditUser: String(auditUser) || '' },)
    },
    cancelAuditParams() {
      const { auditCode } = this.row
      return Object.assign({}, { auditCode }, this.formInline)
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (!val) {
        this.formInline.remarks = ''
        this.$refs.formInline.resetFields()
      }
    }
  },
  created() {

  },
  mounted() {
    this.queryClick(1)
    this._listUserByRoleCode('audti_user')
  },
  methods: {
    async handleExport(id) {
      const { code, msg } = await exportPdf(id)
      code === 0 && this.$notify({
        message: msg,
        type: 'success'
      })
    },
    async _listUserByRoleCode(code) {
      const { code: Code, datas } = await listUserByRoleCode(code)
      if (Code === 0) {
        this.auditUserList = datas
      }
    },
    cancelAudit() {
      this.$refs.formInline.validate(async valid => {
        if (valid) {
          const { code, msg } = await cancelAudit(this.cancelAuditParams)
          if (code === 0) {
            this.$notify({
              title: msg,
              message: msg,
              type: 'success'
            })
            this.dialogVisible = false
            this.queryClick(1)
          }
        }
      })
    },
    handleClick({ id }) {
      this.$router.push({
        path: 'detail',
        append: 'true',
        query: { id }
      })
    },
    async queryClick(flag) {
      try {
        this.loading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await auditPage(this.auditPageParams)
        this.pager = pager
        this.tableDatas = records
        this.loading = false
      } catch (err) { console.log(err) } finally {
        this.loading = false
      }
    },
    resetClick() {
      this.form = this.$options.data.call(this).form

      this.queryClick(1)
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    cancelClick(row) {
      this.row = row
      this.dialogVisible = true
    },
    addClick({ id }) {
      this.$router.push({
        path: 'add',
        append: 'true',
        query: { id, flag: 'asset' }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .el-range-input {
    width: 90px !important;
  }
  .el-range-separator {
    width: 6% !important;
  }
  .el-date-editor {
    width: 100% !important;
  }
</style>
